var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "plan-selector" },
    [
      _vm.editable
        ? _c(
            "c-box",
            {
              staticStyle: { cursor: "pointer" },
              attrs: { flex: "row", align: "center" }
            },
            [
              _c("el-input", {
                attrs: {
                  value: _vm.value,
                  siza: "small",
                  readonly: _vm.visible,
                  placeholder: "请选择货位",
                  "suffix-icon": "el-icon-arrow-down",
                  clearable: ""
                },
                on: {
                  focus: _vm.open,
                  clear: function($event) {
                    return _vm.$emit("input", "")
                  }
                }
              }),
              _vm.showIcon
                ? _c("img", {
                    staticStyle: { margin: "0 18px" },
                    attrs: { src: require("./location.png"), height: "18" },
                    on: { click: _vm.open }
                  })
                : _vm._e()
            ],
            1
          )
        : _vm._e(),
      _c(
        "el-dialog",
        {
          attrs: {
            "close-on-click-modal": false,
            title: _vm.title,
            visible: _vm.visible,
            "append-to-body": "",
            width: _vm.width
          },
          on: {
            "update:visible": function($event) {
              _vm.visible = $event
            },
            close: _vm.onClose
          }
        },
        [
          _c(
            "c-box",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.loading,
                  expression: "loading"
                }
              ],
              attrs: { flex: "row" }
            },
            [
              _c("c-box", { attrs: { width: "170px" } }),
              _c(
                "c-box",
                { attrs: { width: "600px", height: "400px" } },
                [
                  _c(
                    "plan",
                    {
                      ref: "plan",
                      attrs: {
                        width: "100%",
                        showRuler: false,
                        height: "300px",
                        tooltips: { width: 80, trigger: "select" },
                        hover: true,
                        select: _vm.editable
                      },
                      on: { init: _vm.initData, select: _vm.onSelect },
                      scopedSlots: _vm._u([
                        {
                          key: "box",
                          fn: function(scope) {
                            return [
                              _c(
                                "c-box",
                                {
                                  attrs: {
                                    color: "#fff",
                                    height: "100%",
                                    size: "20px",
                                    flex: "row",
                                    align: "center"
                                  }
                                },
                                [_vm._v(" " + _vm._s(scope.data.shelfNo) + " ")]
                              )
                            ]
                          }
                        }
                      ])
                    },
                    [
                      _c(
                        "c-box",
                        {
                          attrs: { slot: "tooltips", padding: "0 5px" },
                          slot: "tooltips"
                        },
                        _vm._l(_vm.levelList, function(level, idx) {
                          return _c(
                            "div",
                            {
                              key: idx,
                              staticClass: "level",
                              class: { active: level.locationNo === _vm.value },
                              on: {
                                click: function($event) {
                                  return _vm.choose(level)
                                }
                              }
                            },
                            [_vm._v(" " + _vm._s(level.locationNo) + " ")]
                          )
                        }),
                        0
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }